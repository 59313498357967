
import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';

interface Sala {
  id?: number;
  name: string;
}

export default Vue.extend({
  name: 'SalasDetail',
  props: {
    isToNew: Boolean,
    editRoom: Object as () => Sala,
  },
  data() {
    return {
      dialog: false,
      sala: { name: '' } as Sala,
      originalName: '', // New property to store the original name
      valid: false,
      rules: {
        required: [(v: string) => !!v || 'Required.'],
      },
      nameRules: [
        (v: string) => !!v || 'Name is required.',
        (v: string) => (v && v.length <= 50) || 'Name must be less than 50 characters',
      ],
    };
  },
  computed: {
    ...mapGetters({
      corp: 'auth/corp',
      softwareId: 'auth/softwareId',
    }),
  },
  watch: {
    isToNew: {
      handler(newVal) {
        if (newVal) {
          this.sala = { name: '' } as Sala;
          this.originalName = ''; // Reset original name
        }
      },
      immediate: true,
    },
    editRoom: {
      handler(newVal) {
        if (newVal && !this.isToNew) {
          var sala = { ...newVal };
          this.sala.name = sala.nombre
          this.originalName = newVal.nombre; // Store the original name
          this.dialog = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit('refreshList');
    },
    async validate(event: Event) {
      event.preventDefault(); // Prevent default form submission
      if (this.valid) {
        try {
          if (this.isToNew) {
            await axios.post('http://177.11.49.135:4000/rooms', {
              name: this.sala.name,
              corpId: this.corp.id,
              softwareId: this.softwareId,
            });
            this.$emit('create-sala', { name: this.sala.name });
          } else {
            await axios.put('http://177.11.49.135:4000/rooms', {
              name: this.originalName,
              corpId: this.corp.id,
              softwareId: this.softwareId,
              newName: this.sala.name,
            });
            this.$emit('refreshList');
          }

          this.closeDialog();
          this.$store.dispatch('snackbar/showSnack', {
            message: this.isToNew ? 'Sala created successfully' : 'Sala updated successfully',
            color: 'success',
            timeout: 3000,
          });
        } catch (error) {
          this.$store.dispatch('snackbar/showSnack', {
            message: 'Failed to save Sala',
            color: 'error',
            timeout: 3000,
          });
        }
      }
    },
  },
});
