
/* Possible bottom image */
/* 
<!-- #region Bottom Image -->
        <v-row class="d-flex align-end" v-if="!!dataBottomImage">
          <v-col class="d-flex align-end justify-center">
            <img :src="dataBottomImage" class="bottomImgHeight" />
          </v-col>
        </v-row>
        <!-- #endregion -->
*/
import Vue from "vue";

import { mapActions, mapGetters } from "vuex";
import { tokenHeader } from "@/services/auth";
import config from "../config.js";
import store from "@/store/index";
import i18n from "@/plugins/i18n";
// import LanguageSwitcher from "@/components/templates/LanguageSwitcher.vue";
import axios from "axios";
import * as action from "@/store/actions";
import { Corp } from "@/types/conf";

export default Vue.extend({
  name: "Login",
  components: {
    // LanguageSwitcher,
  },
  data() {
    return {
      loaded: false,
      loading: false,
      hoverColorLocal: null,
      menuIconColorLocal: null,
      menuFontColorLocal: null,

      loginImageLocal: "",
      loginBottomImageLocal: "",

      errorMessage: "",
      failed_image: false,
      logo: null,
      hide: false,
      show: false,
      errorDialog: false,
      valid: true,
      name: "",
      password: "",
      lazy: false,
      checkCorp: false,
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",

      backgroundColor: "lookAndFeel/backgroundColor",
      loginTextColor: "lookAndFeel/loginTextColor",

      loginImage: "lookAndFeel/loginImage",
      loginBottomImage: "lookAndFeel/loginBottomImage",
    }),
    nameRules() {
      return [
        (v: string) => !!v || i18n.t("loginView.required"),
        (v: string) => (v && v.length >= 4) || i18n.t("loginView.userLength"),
      ];
    },
    passwordLengthRules() {
      return [
        (v: string) => !!v || i18n.t("loginView.required"),
        (v: string) =>
          (v && v.length >= 3) || i18n.t("loginView.passwordLength"),
      ];
    },
    dataImage(): string | null {
      return !this.loginImageLocal ? null : this.loginImageLocal;
    },
    dataBottomImage(): string | null {
      return !this.loginBottomImageLocal ? null : this.loginBottomImageLocal;
    },
  },

  async mounted() {
    this.hoverColorLocal = await this.hoverColor;
    this.menuIconColorLocal = await this.menuIconColor;
    this.menuFontColorLocal = await this.menuFontColor;

    this.loginImageLocal = await this.loginImage;
    this.loginBottomImageLocal = await this.loginBottomImage;

    await this.loadImages();
  },

  async beforeRouteEnter(to, from, next) {

    if (to.params.corp) {
      store.commit("lookAndFeel/SET_CORP_URL", window.location.pathname.split("/")[2]);
      store.commit("lookAndFeel/SET_CORP_NAME", to.params.corp);
      await store
        .dispatch("lookAndFeel/checkLookandfeel")
        .then(() => {
          next();
        })
        .catch((err) => {
          next(err);
        });
    } else {
      next();
    }
  },

  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      initPanel: "panel/initPanel",
      setExchange: "auth/setExchange"
    }),

    async loadImages() {
      let result;
      const url =
        config.url + "lookandfeel/" + store.state.lookAndFeel.corpName;

      await axios
        .get(url)
        .then(async (response) => {
          result = response.data;
          this.loginImageLocal =  result["loginImage"];
          this.loginBottomImageLocal = result["loginBottomImageLocal"];
        })
        .catch((error) => {
          console.warn(error);
        })
        .finally(() => {
          this.loaded = true;
        });
    },

    async validate() {

      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {

        this.loading = true;

        const regExpValidation = /[a-zA-Z0-9 !"#$%&()*+,\-./<>.;=?@^~[\]]/g;        
        let credentials = {
          nick: regExpValidation.test(this.name) ? this.name : "",
          pass: regExpValidation.test(this.password) ? this.password : "",
          corpoName: store.state.lookAndFeel.corpName,   
        };

        const response = await this.signIn(credentials);

        if (response < 400) {
          let corp: Corp;
          const headers = tokenHeader();
          await axios
            .get(action.CORP_CONTROLLER + "/" + this.user.corpId, { withCredentials: true, headers })
            .then((res) => {
              corp = res.data;
              store.commit("auth/SET_CORP", corp);
              localStorage.setItem("corp", corp.name || "");
            })
            .then(() => {
              store.commit("lookAndFeel/SET_CORP_URL", window.location.pathname.split("/")[2]);
              store.commit("lookAndFeel/SET_CORP_NAME", corp.name);
              store.dispatch("lookAndFeel/checkLookandfeel");
            })
            .then(() => {
              this.postLogin(corp);
            });

          await axios.get(action.GET_EXCHANGE, { withCredentials: true, headers })
            .then(res => {
              this.setExchange(res.data);
            })
          await axios.get(action.GET_USER_LANG, { withCredentials: true, headers })
            .then(res => {
              this.$root.$i18n.locale = res.data;
            })
          await axios.get(action.GET_USER_THEME, { withCredentials: true, headers })
            .then(res => {
              this.$vuetify.theme.dark = !!res.data;
            })
          var responseIp = await axios.get('https://api.ipify.org?format=json');
          await axios.post("http://177.11.49.135:4000/corps/check-corpId", {
            corpId: this.user.corpId,
            //ip : responseIp.data.ip
            ip : window.location.hostname
          })
          .then(response => {
            if(response.status = 200) 
            {
              store.commit("auth/SET_ROOMS", 1);
              this.checkCorp = true;
            }
          })
          .catch(response => {
            store.commit("auth/SET_ROOMS", 0);
          })
          var responseIp = await axios.get('https://api.ipify.org?format=json');
          await axios.post("http://177.11.49.135:4000/softwares/check-ip", {
            //ip : responseIp.data.ip
            ip : window.location.hostname
          })
          .then(response => {
            store.commit("auth/SET_SOFTWAREID", response.data.data.id);
          })
          .catch(response => {
            store.commit("auth/SET_ROOMS", 0);
            store.commit("auth/SET_SOFTWAREID", -10);
          })
        } else {
          if (response < 500) {
            this.errorMessage = `${i18n.t("loginView.credentialsError")}`;
          } else {
            this.errorMessage = `${i18n.t("loginView.serverError")}`;
          }
          this.loading = false;
          this.errorDialog = true;
        }

      }
    },
    async postLogin(corp: Corp) {

      try {
        await this.initPanel();
      } catch (error) {
        console.log("The Panel will not work. Reload the page or contact support team.");
      }

      switch (store.state.auth.user.level) {
        case 1:
          await this.$router.replace({
            name: "home",
            params: { corp: "supervisor", lang: i18n.locale },
          });
          break;

        case 5:
          await this.$router.replace({
            name: "panel",
          });
          break;
      
        default:
          await this.$router.replace({
            name: "home",
            params: { corp: window.location.pathname.split("/")[2], lang: i18n.locale },
          });
          break;
      }
    },
  },
  beforeMount() {
    document.cookie = 'selectedCorpName' + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;"
  },
});
