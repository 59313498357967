import { render, staticRenderFns } from "./SalasDetail.vue?vue&type=template&id=60fa823b&scoped=true"
import script from "./SalasDetail.vue?vue&type=script&lang=ts"
export * from "./SalasDetail.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60fa823b",
  null
  
)

export default component.exports