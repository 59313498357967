

import Vue from "vue";

import AlertMessage from "@/components/templates/AlertMessage.vue";
import NavigationDrawer from "@/components/templates/NavigationDrawer/NavigationDrawer.vue";
import Top from "./components/templates/Top.vue";

import { mapActions, mapGetters } from "vuex";
import Panel from "@/plugins/panelHub";

export default Vue.extend({
  name: "App",

  components: {
    AlertMessage,
    NavigationDrawer,
    Top
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      corp: "auth/corp"
    }),
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },

  methods: {
    handleKeyDown(event: KeyboardEvent) {
      if (event.key === "F5") {
        if(window.location.pathname != "/login/supervisor"){
          event.preventDefault();
          console.log(this.corp)
          if(this.corp.id == 0){
            window.location.href = "/login/supervisor"
          }
          else window.location.href = "/login" + this.corp.monPath
        }
        
      }
    }
  }
});
